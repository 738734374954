import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Container, ModalContent, Header, CloseButton, Content } from "./style";
import Dropdown from "../DropDown";

interface TherapistType {
  id: string;
  lastName: string;
  firstName: string;
}

interface AssignTherapistModalProps {
  show: boolean;
  therapistList: Array<TherapistType>;
  therapistId: string;
  handleClose: () => void;
  handleChangeTherapist: (therapistId: string) => void;
  handleAssignTherapist: () => void;
}

interface States {}
type Props = AssignTherapistModalProps & WithTranslation;

class AssignTherapistModal extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      therapistId: "",
    };
  }

  render() {
    const {
      handleChangeTherapist,
      therapistId,
      show,
      handleClose,
      therapistList,
      handleAssignTherapist,
    } = this.props;
    const options = therapistList
      .sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      })
      .map((therapist: TherapistType) => {
        return {
          text: therapist.lastName + therapist.firstName,
          value: therapist.id,
        };
      });
    const defaultTherapist = therapistList.filter(
      (therapist) => therapist.id === therapistId
    );
    const selectedTherapistName =
      defaultTherapist.length > 0
        ? defaultTherapist[0].lastName + defaultTherapist[0].firstName
        : "";
    return (
      <Container id="ModalContainer" show={show}>
        <ModalContent id="ModalContent">
          <Header>Select a therapist</Header>
          <Content>
            <Dropdown
              componentName="admin - assign therapist"
              type="therapist id"
              defaultSelection={therapistId}
              selectedText={selectedTherapistName}
              onOptionChange={handleChangeTherapist}
              options={options}
            ></Dropdown>
          </Content>
          <Content>
            <CloseButton onClick={handleClose}>Cancel</CloseButton>
            <CloseButton onClick={handleAssignTherapist}>Assign</CloseButton>
          </Content>
        </ModalContent>
      </Container>
    );
  }
}

export default withTranslation()(AssignTherapistModal);
