import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import NotificationModal from "../../../common/NotificationModal";
import TableModal from "../../../common/TableModal";
import { match } from "react-router-dom";
import { History, LocationState } from "history";
import DropDown from "../../../common/DropDown";
import { COUPON_DISCOUNT_TYPE } from "../../../../constants/common";
import { CURRENCY_LIST, CURRENCY_OPTIONS } from "../../../../constants/payment";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
} from "../../styles";
import {
  DiscountContainer,
  DiscountTitle,
  DiscountLabel,
  DiscountInfoContainer,
  DiscountInfoInput,
  DiscountInfoSubtitle,
  DiscountInfoPostfix,
  ApplyToContainer,
  ApplyContainer,
  ApplyTitle,
  ApplyToOption,
  DayAndHour,
  DayAndHourText,
} from "../styles";
import {
  DiscountTypeContainer,
  ApplyAllContainer,
  OtherContainer,
} from "./styles";
import moment from "moment";
import { DAYS_AND_HOURS_BETWEEN_TWO_DATE } from "../../../../../src/util/common";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";

interface Match {
  productId: string;
}

interface CreateCouponProps {
  history: History<LocationState>;
  match: match<Match>;
}

interface productType {
  id: number;
  name: string;
}

interface CreateCouponStates {
  [key: string]:
    | string
    | number
    | Array<number>
    | Array<string>
    | boolean
    | Array<productType>;
  products: Array<productType>;
  number: string;
  prefix: string;
  amountOff: string;
  applyAll: string;
  applyTo: Array<number>;
  currency: string;
  discountType: string;
  maxDiscount: string;
  maxRedemption: string;
  offRate: string;
  partnerId: number;
  redeemBy: string;
  redeemByDays: number;
  redeemByHours: number;
  redeemStart: string;
  message: string;
  showModal: boolean;
  showTableModal: boolean;
  tableData: Array<string>;
  notificationStatus: boolean;
}

type Props = CreateCouponProps & WithTranslation;
class CreateCoupon extends Component<Props, CreateCouponStates> {
  constructor(props: Props) {
    super(props);
    const { productId } = props.match.params;
    this.state = {
      products: [],
      number: "1",
      prefix: "",
      amountOff: "",
      applyAll: "false",
      applyTo: productId === "0" ? [] : [parseInt(productId)],
      currency: CURRENCY_LIST[0],
      discountType: COUPON_DISCOUNT_TYPE.percent,
      maxDiscount: "",
      maxRedemption: "",
      offRate: "",
      partnerId: 0,
      redeemBy: "",
      redeemByDays: 0,
      redeemByHours: 0,
      redeemStart: "",
      message: "",
      showModal: false,
      showTableModal: false,
      tableData: [],
      notificationStatus: false,
    };
  }

  componentDidMount = () => {
    const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
    axios
      .get(api_address + "api/admin/coupon/allActiveProduct", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { products } = res.data;
        const productList = products.map((product) => {
          return {
            name: product.name,
            id: product.id,
          };
        });
        this.setState({
          products: productList,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  handleStringInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState({ [id]: value });
  };

  handleNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    if (`${Number(value)}` === value) {
      this.setState({ [id]: +value });
    } else if (value === "") {
      this.setState({ [id]: value });
    }
  };

  handleCurrencyChange = (value: string) => {
    if (value != null)
      this.setState({
        currency: value,
      });
  };

  handlePercentClick = () => {
    this.setState({
      discountType: COUPON_DISCOUNT_TYPE.percent,
      amountOff: "",
    });
  };

  handleAmountClick = () => {
    this.setState({
      discountType: COUPON_DISCOUNT_TYPE.amount,
      offRate: "",
      maxDiscount: "",
    });
  };

  handleApplyAllChange = (value: string) => {
    if (value != null) {
      let { applyTo } = this.state;
      if (value === "true") {
        applyTo = [];
      }
      this.setState({
        applyAll: value,
        applyTo: applyTo,
      });
    }
  };

  handleApplyToOptionClick = (id: number) => {
    let { applyTo } = this.state;
    const index = applyTo.indexOf(id);
    const isUncheck = index > -1;
    if (isUncheck) {
      applyTo = applyTo.filter((item) => item !== id);
    } else {
      applyTo.push(id);
    }
    this.setState({
      applyTo,
    });
  };

  handleRedeemByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const { daysFromNow, hoursFromNow } = DAYS_AND_HOURS_BETWEEN_TWO_DATE(
      moment().format("YYYY-MM-DD HH:mm:ss"),
      value
    );
    this.setState({
      redeemBy: value,
      redeemByDays: daysFromNow,
      redeemByHours: hoursFromNow,
    });
  };

  onCancelClick = () => {
    this.props.history.push("/admin/coupon");
  };

  onFinishClick = () => {
    this.setState({
      showTableModal: false,
    });
    this.props.history.push("/admin/coupon");
  };

  onSaveClick = () => {
    try {
      this.validation();
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const {
        prefix,
        number,
        currency,
        discountType,
        offRate,
        maxDiscount,
        amountOff,
        applyAll,
        applyTo,
        redeemBy,
        maxRedemption,
      } = this.state;
      axios
        .post(
          api_address + "api/admin/coupon/",
          {
            prefix,
            number,
            currency,
            discountType,
            offRate,
            maxDiscount,
            amountOff,
            applyAll,
            applyTo,
            maxRedemption,
            redeemBy: redeemBy !== "" ? redeemBy : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const { status, message } = res.data;
          if (status) {
            this.setState({
              tableData: res.data.couponData.couponCode,
              showTableModal: true,
            });
          } else {
            this.displayErrorMessage(message);
          }
        })
        .catch((err) => {
          console.error(err.message);
          this.displayErrorMessage(err.message);
        });
    } catch (error: any) {
      console.error(error.message);
      this.displayErrorMessage(error.message);
    }
  };
  validation = () => {
    const {
      discountType,
      offRate,
      amountOff,
      applyAll,
      applyTo,
      maxRedemption,
    } = this.state;
    const { percent, amount } = COUPON_DISCOUNT_TYPE;
    if (discountType === percent) {
      if (parseInt(offRate) > 100 || parseInt(offRate) < 0) {
        throw new Error("offRate must between 0 and 100");
      }
    } else if (discountType === amount) {
      if (amountOff === "") {
        throw new Error("amountOff can't be empty");
      }
    }
    if (applyAll === "false") {
      if (applyTo.length === 0) {
        throw new Error("applyTo can't be empty");
      }
    }
    if (maxRedemption === "") {
      throw new Error("maxRedemption can't be empty");
    }
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      message: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  render = () => {
    const { t } = this.props;
    const {
      showModal,
      message,
      notificationStatus,
      currency,
      discountType,
      offRate,
      maxDiscount,
      amountOff,
      applyAll,
      applyTo,
      products,
      redeemBy,
      redeemByDays,
      redeemByHours,
      maxRedemption,
      prefix,
      number,
      tableData,
      showTableModal,
    } = this.state;
    const ApplyAllOptions = [
      { text: "true", value: "true" },
      { text: "false", value: "false" },
    ];
    return (
      <Container>
        <TableModal
          data={tableData}
          show={showTableModal}
          handleClose={this.onFinishClick}
        />
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <DiscountContainer>
          <DiscountTitle>{t("admin.coupon.discountType")}</DiscountTitle>
          <ContentContainer>
            <ContentSubtitle>{t("admin.payment.currency")}</ContentSubtitle>
            <DropDown
              componentName="admin - coupon"
              type="currency"
              defaultSelection={currency}
              selectedText={currency}
              options={CURRENCY_OPTIONS}
              onOptionChange={this.handleCurrencyChange}
            />
          </ContentContainer>
          <DiscountTypeContainer>
            <DiscountLabel
              onClick={this.handlePercentClick}
              checked={discountType === COUPON_DISCOUNT_TYPE.percent}
            >
              {t("admin.coupon.percent")}
            </DiscountLabel>
            <DiscountInfoContainer
              checked={discountType === COUPON_DISCOUNT_TYPE.percent}
            >
              <ContentContainer>
                <DiscountInfoSubtitle>
                  {t("admin.coupon.offRate")}
                </DiscountInfoSubtitle>
                <DiscountInfoInput
                  onChange={this.handleNumberInputChange}
                  id="offRate"
                  type="text"
                  disabled={discountType !== COUPON_DISCOUNT_TYPE.percent}
                  value={offRate}
                />
                <DiscountInfoPostfix>% off</DiscountInfoPostfix>
              </ContentContainer>
              <ContentContainer>
                <DiscountInfoSubtitle>
                  {t("admin.coupon.maxDiscount")}
                </DiscountInfoSubtitle>
                <DiscountInfoInput
                  onChange={this.handleNumberInputChange}
                  id="maxDiscount"
                  type="text"
                  disabled={discountType !== COUPON_DISCOUNT_TYPE.percent}
                  value={maxDiscount}
                />
              </ContentContainer>
            </DiscountInfoContainer>
          </DiscountTypeContainer>

          <DiscountTypeContainer>
            <DiscountLabel
              onClick={this.handleAmountClick}
              checked={discountType === COUPON_DISCOUNT_TYPE.amount}
            >
              {t("admin.coupon.amount")}
            </DiscountLabel>

            <DiscountInfoContainer
              checked={discountType === COUPON_DISCOUNT_TYPE.amount}
            >
              <ContentContainer>
                <DiscountInfoSubtitle>
                  {t("admin.coupon.amountOff")}
                </DiscountInfoSubtitle>
                <DiscountInfoInput
                  onChange={this.handleNumberInputChange}
                  id="amountOff"
                  type="text"
                  disabled={discountType !== COUPON_DISCOUNT_TYPE.amount}
                  value={amountOff}
                />
              </ContentContainer>
            </DiscountInfoContainer>
          </DiscountTypeContainer>
        </DiscountContainer>
        <ApplyContainer>
          <ApplyTitle>{t("admin.coupon.applyTo")}</ApplyTitle>
          <ApplyAllContainer>
            <DiscountInfoSubtitle>
              {t("admin.coupon.applyAll")}
            </DiscountInfoSubtitle>
            <DropDown
              componentName="admin - coupon"
              type="apply all"
              defaultSelection={applyAll}
              selectedText={applyAll}
              options={ApplyAllOptions}
              onOptionChange={this.handleApplyAllChange}
            ></DropDown>
          </ApplyAllContainer>
          {applyAll === "false" && (
            <>
              <DiscountInfoSubtitle>
                {t("admin.coupon.applyTo")}
              </DiscountInfoSubtitle>
              <ApplyToContainer>
                {products.map((product) => {
                  const { id, name } = product;
                  return (
                    <ApplyToOption
                      checked={applyTo.includes(id)}
                      onClick={() => this.handleApplyToOptionClick(id)}
                    >
                      {name}
                    </ApplyToOption>
                  );
                })}
              </ApplyToContainer>
            </>
          )}
        </ApplyContainer>
        <OtherContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.redeemBy")}</ContentSubtitle>
            <Input
              id="redeemBy"
              type="datetime-local"
              onChange={this.handleRedeemByChange}
              value={redeemBy}
            />
            <DayAndHour>{redeemByDays}</DayAndHour>
            <DayAndHourText>{t("admin.coupon.days")}</DayAndHourText>
            <DayAndHour>{redeemByHours}</DayAndHour>
            <DayAndHourText>{t("admin.coupon.hours")}</DayAndHourText>
          </ContentContainer>

          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.maxRedemption")}</ContentSubtitle>
            <Input
              id="maxRedemption"
              type="text"
              onChange={this.handleNumberInputChange}
              value={maxRedemption}
            />
          </ContentContainer>

          <ContentContainer>
            <ContentSubtitle>
              {t("admin.coupon.couponQuantity")}
            </ContentSubtitle>
            <Input
              id="number"
              type="text"
              onChange={this.handleNumberInputChange}
              value={number}
            />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.prefix")}</ContentSubtitle>
            <Input
              id="prefix"
              type="text"
              onChange={this.handleStringInputChange}
              value={prefix}
            />
          </ContentContainer>
        </OtherContainer>

        <ContentContainer>
          <CancelButton onClick={this.onCancelClick}>
            {t("admin.common.cancel")}
          </CancelButton>

          <SaveButton onClick={this.onSaveClick}>
            {t("admin.common.save")}
          </SaveButton>
        </ContentContainer>
      </Container>
    );
  };
}

export default withTranslation()(CreateCoupon);
