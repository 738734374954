import { Component } from "react";
import { match } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import api_address from "../../../../constants/config";
import { History, LocationState } from "history";
import NotificationModal from "../../../common/NotificationModal";
import {
  Container,
  ContentContainer,
  ContentSubtitle,
  Input,
  SaveButton,
  CancelButton,
} from "../../styles";
import {
  DiscountContainer,
  DiscountTitle,
  DayAndHour,
  DayAndHourText,
  RedeemByButton,
} from "../styles";
import { SubContainer, User, UserContainer } from "./style";
import moment from "moment";
import { DAYS_AND_HOURS_BETWEEN_TWO_DATE } from "../../../../../src/util/common";
import { JS_COOKIE } from "util/auth";
import { ADMIN_COOKIE_NAME } from "constants/admin";

interface productType {
  id: string;
  name: string;
}

interface Match {
  id: string;
}

interface CouponEditProps {
  match: match<Match>;
  history: History<LocationState>;
}

interface CouponEditStates {
  [key: string]:
    | string
    | number
    | Array<productType>
    | Array<number>
    | boolean
    | undefined;
  amountOff: number;
  applyAll: boolean;
  applyTo: Array<productType>;
  couponCode: string;
  currency: string;
  discountType: string;
  maxDiscount: number;
  maxRedemption: number;
  currentRedemption: number;
  offRate: string;
  partnerId: number;
  hasRedeemBy: boolean;
  redeemBy: string | undefined;
  redeemByDays: number;
  redeemByHours: number;
  redeemStart: string;
  message: string;
  showModal: boolean;
  notificationStatus: boolean;
  showUsers: boolean;
  users: Array<number>;
}

type Props = CouponEditProps & WithTranslation;
class CouponEdit extends Component<Props, CouponEditStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      amountOff: 0,
      applyAll: false,
      applyTo: [],
      couponCode: "",
      currency: "",
      discountType: "",
      maxDiscount: 0,
      maxRedemption: 0,
      currentRedemption: 0,
      offRate: "",
      partnerId: 0,
      hasRedeemBy: false,
      redeemBy: "",
      redeemByDays: 0,
      redeemByHours: 0,
      redeemStart: "",
      message: "",
      showModal: false,
      notificationStatus: false,
      showUsers: false,
      users: [],
    };
  }

  componentDidMount = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const res = await axios.get(api_address + "api/admin/coupon/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { redeemBy, redeemStart } = res.data.coupon;

      const { daysFromNow, hoursFromNow } = DAYS_AND_HOURS_BETWEEN_TWO_DATE(
        new Date().toUTCString(),
        redeemBy
      );
      this.setState({
        ...res.data.coupon,
        hasRedeemBy: redeemBy ? true : false,
        redeemBy: redeemBy && moment(redeemBy).format("YYYY-MM-DDThh:mm"),
        redeemByDays: redeemBy ? daysFromNow : 0,
        redeemByHours: redeemBy ? hoursFromNow : 0,
        redeemStart:
          redeemStart && moment(redeemStart).format("YYYY-MM-DDThh:mm"),
      });
    } catch (err: any) {
      this.showNotification(err.message, false);
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    if (`${Number(value)}` === value) {
      this.setState({ [id]: +value });
    }
  };

  handleRedeemByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const { daysFromNow, hoursFromNow } = DAYS_AND_HOURS_BETWEEN_TWO_DATE(
      moment().format("YYYY-MM-DD HH:mm:ss"),
      value
    );
    this.setState({
      redeemBy: value,
      redeemByDays: daysFromNow,
      redeemByHours: hoursFromNow,
    });
  };

  handleCouponMethodChange = (value: string) => {
    if (value != null)
      this.setState({
        couponMethod: value,
      });
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  enableRedeemByClick = () => {
    this.setState({
      hasRedeemBy: true,
    });
  };

  disableRedeemByClick = () => {
    this.setState({
      hasRedeemBy: false,
      redeemBy: undefined,
    });
  };

  onCancelClick = () => {
    this.props.history.push("/admin/coupon");
  };

  onSaveClick = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const { redeemBy, maxRedemption, applyAll, applyTo, hasRedeemBy } =
        this.state;
      const res = await axios.put(
        api_address + "api/admin/coupon/" + id,
        {
          redeemBy: hasRedeemBy ? redeemBy : undefined,
          maxRedemption,
          applyAll,
          applyTo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { message, status } = res.data;
      this.showNotification(message, status);
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  showAllUsers = async () => {
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { id } = this.props.match.params;
      const res = await axios.get(
        api_address + "api/admin/coupon/getUserByCoupon/" + id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.setState({ users: res.data.users, showUsers: true });
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  render = () => {
    const { t } = this.props;
    const { id } = this.props.match.params;
    const {
      showUsers,
      users,
      hasRedeemBy,
      showModal,
      message,
      notificationStatus,
      amountOff,
      applyAll,
      applyTo,
      couponCode,
      currency,
      discountType,
      maxDiscount,
      maxRedemption,
      currentRedemption,
      offRate,
      redeemBy,
      redeemStart,
      redeemByDays,
      redeemByHours,
    } = this.state;
    const applyAllText = applyAll ? "Yes" : "No";
    let applyToText = "";
    applyTo.forEach(
      (product: productType) =>
        (applyToText += t(`admin.product.${product.name}`))
    );

    return (
      <Container>
        <NotificationModal
          show={showModal}
          message={message}
          status={notificationStatus}
        />
        <SubContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.id")}</ContentSubtitle>
            <Input disabled={true} value={id} />
          </ContentContainer>

          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.couponCode")}</ContentSubtitle>
            <Input disabled={true} value={couponCode} />
          </ContentContainer>

          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.redeemStart")}</ContentSubtitle>
            <Input type="datetime-local" disabled={true} value={redeemStart} />
          </ContentContainer>

          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.maxRedemption")}</ContentSubtitle>
            <Input
              id="maxRedemption"
              value={maxRedemption}
              onChange={this.handleInputChange}
            />
          </ContentContainer>

          <ContentContainer>
            <label
              style={{
                lineHeight: "50px",
                fontSize: "18px",
                width: "200px",
                margin: "0 10px",
              }}
            >
              {t("admin.coupon.currentRedemption")}
            </label>
            <Input disabled={true} value={currentRedemption} />
          </ContentContainer>
        </SubContainer>

        {hasRedeemBy ? (
          <SubContainer>
            <ContentContainer>
              <ContentSubtitle>{t("admin.coupon.redeemBy")}</ContentSubtitle>
              <Input
                id="redeemBy"
                type="datetime-local"
                onChange={this.handleRedeemByChange}
                value={redeemBy}
              />
            </ContentContainer>
            <ContentContainer>
              <DayAndHour>{redeemByDays}</DayAndHour>
              <DayAndHourText>{t("admin.coupon.days")}</DayAndHourText>
              <DayAndHour>{redeemByHours}</DayAndHour>
              <DayAndHourText>{t("admin.coupon.hours")}</DayAndHourText>
            </ContentContainer>
            <RedeemByButton onClick={this.disableRedeemByClick}>
              {t("admin.coupon.disableRedeemBy")}
            </RedeemByButton>
          </SubContainer>
        ) : (
          <RedeemByButton onClick={this.enableRedeemByClick}>
            {t("admin.coupon.activateCoupon")}
          </RedeemByButton>
        )}

        <DiscountContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.discountType")}</ContentSubtitle>
            <Input disabled={true} value={discountType} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.payment.currency")}</ContentSubtitle>
            <Input disabled={true} value={currency} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.offRate")}</ContentSubtitle>
            <Input disabled={true} value={offRate} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.maxDiscount")}</ContentSubtitle>
            <Input disabled={true} value={maxDiscount} />
          </ContentContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.amountOff")}</ContentSubtitle>
            <Input disabled={true} value={amountOff} />
          </ContentContainer>
        </DiscountContainer>

        <DiscountContainer>
          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.applyAll")}</ContentSubtitle>
            <Input disabled={true} value={applyAllText} />
          </ContentContainer>

          <ContentContainer>
            <ContentSubtitle>{t("admin.coupon.applyTo")}</ContentSubtitle>
            <Input disabled={true} value={applyToText} />
          </ContentContainer>
        </DiscountContainer>

        <DiscountContainer>
          <DiscountTitle>
            {t("admin.coupon.userWhoUsedThisCoupon")}
          </DiscountTitle>

          {showUsers ? (
            <UserContainer>
              {users.map((u) => (
                <User
                  onClick={() =>
                    this.props.history.push("/admin/user/edit/" + u)
                  }
                >
                  {u}
                </User>
              ))}
            </UserContainer>
          ) : (
            <ContentContainer>
              <RedeemByButton onClick={this.showAllUsers}>
                {t("admin.coupon.showAllUsers")}
              </RedeemByButton>
            </ContentContainer>
          )}
        </DiscountContainer>

        <ContentContainer>
          <CancelButton onClick={this.onCancelClick}>
            {t("admin.common.cancel")}
          </CancelButton>

          <SaveButton onClick={this.onSaveClick}>
            {t("admin.common.save")}
          </SaveButton>
        </ContentContainer>
      </Container>
    );
  };
}

export default withTranslation()(CouponEdit);
