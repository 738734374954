import styled from "styled-components";

export const DayAndHour = styled.div`
  border-radius: 4px;
  margin: 0 10px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
`;

export const DayAndHourText = styled.div`
  margin: 0 5px;
  line-height: 50px;
  font-size: 20px;
  white-space: pre;
`;

export const DiscountContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: solid 1px #a8bf96;
  border-radius: 10px;
  margin: 10px 0;
`;

export const DiscountTitle = styled.div`
  width: 100%;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #a8bf96;
  color: #ffffff;
  font-size: 20px;
  height: 36px;
  line-height: 36px;
`;

export const ApplyContainer = styled.div`
  margin-top: 10px;
  border: solid 1px #a8bf96;
  border-radius: 10px;
`;

export const ApplyTitle = styled.div`
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #a8bf96;
  color: #ffffff;
  font-size: 20px;
  height: 36px;
  line-height: 36px;
`;

export const DiscountLabel = styled.div`
  width: 100%;
  margin: 0 20px;
  border-radius: 15px;
  height: 44px;
  line-height: 44px;
  font-size: 20px;
  text-align: center;
  background-color: ${(props: { checked: boolean }) =>
    props.checked ? "#A8BF96" : "#E0E0E0"};
  color: #ffffff;
  :hover {
    cursor: pointer;
  }
`;

export const DiscountInfoContainer = styled.div`
  width: 100%;
  margin: 5px 20px;
  border-radius: 15px;
  border: solid 1px
    ${(props: { checked: boolean }) => (props.checked ? "#A8BF96" : "#E5E5E5")};
  background-color: ${(props: { checked: boolean }) =>
    props.checked ? "#F9FFF6" : "#FFFFFF"};
  height: 160px;
`;

export const DiscountInfoSubtitle = styled.label`
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  margin: 0 15px;
`;

export const DiscountInfoPostfix = styled.label`
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  margin: 0 10px;
`;

export const DiscountInfoInput = styled.input`
  width: 50px;
`;

export const ApplyToContainer = styled.div`
  border: solid 1px #a8bf96;
  border-radius: 4px;
  margin: 5px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ApplyToOption = styled.div`
  flex: 1 1 150px;
  font-size: 20px;
  margin: 5px;
  text-align: center;
  height: 50px;
  display: grid;
  place-items: center;
  cursor: pointer;
  border: solid 1px
    ${(props: { checked: boolean }) => (props.checked ? "#F9FFF6" : "#A8BF96")};
  border-radius: 4px;
  background-color: ${(props: { checked: boolean }) =>
    props.checked ? "#A8BF96" : "#FFFFFF"};
`;

export const RedeemByButton = styled.div`
  margin: 0 auto;
  width: 250px;
  height: 50px;
  background-color: #f9fff6;
  border: solid 1px #a8bf96;
  color: #000000;
  font-size: 20px;
  display: grid;
  place-items: center;
  border-radius: 10px;
  :hover {
    cursor: pointer;
  }
`;
