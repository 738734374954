import { CURRENCY } from "../constants/common";

export const CURRENCY_LIST = [CURRENCY.cny, CURRENCY.usd, CURRENCY.twd];

export const CURRENCY_OPTIONS = CURRENCY_LIST.map((currency: string) => {
  return {
    text: currency,
    value: currency,
  };
});

export const PAYMENT_STATUS = ["pending", "canceled", "success"];

export const PAYMENT_METHOD = ["stripe", "wechatpay", "alipay", "coupon"];
