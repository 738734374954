import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Modal, Upload, Button } from "antd";
import {
  Container,
  ModalContent,
  Content,
  VideoContainer,
  Video,
  Comment,
  DeleteButton,
} from "./style";
import { BackButton } from "../../../../src/components/Admin/styles";
import { ChevronLeft } from "lucide-react";
import {
  UploadOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import getSignedUrl from "../../../util/Downloader";
import Notification from "../NotificationModal";

const { Dragger } = Upload;

interface videoType {
  transactionId: string;
  video1: string;
  video1comment: string;
  encodedVideoName1: string;
  video1aws: boolean;
  completed1: number;
  videoID: number;
  video2: string;
  video2comment: string;
  encodedVideoName2: string;
  video2aws: boolean;
  completed2: number;
}

interface VideoModalProps {
  show: boolean;
  video: videoType;
  handleSave: () => void;
  handleClose: () => void;
  handleDelete: (videoKey: "video1" | "video2") => void;
  handleUploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface States {
  showNotification: boolean;
  message: string;
  notificationStatus: boolean;
  confirmDelete: boolean;
  videoToDelete: "video1" | "video2" | null;
}

type Props = VideoModalProps & WithTranslation;

class VideoModal extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showNotification: false,
      message: "",
      notificationStatus: false,
      confirmDelete: false,
      videoToDelete: null,
    };
  }

  componentDidUpdate = (prevProps: VideoModalProps) => {
    const { video1aws, video2aws } = this.props.video;
    if (
      ((!prevProps.video.video1aws && video1aws) ||
        (!prevProps.video.video2aws && video2aws)) &&
      prevProps.video.video1 != null
    ) {
      this.showNotification(
        "Video upload successful, click save to update the database",
        true
      );
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showNotification: true,
    });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };

  showDeleteConfirm = (videoKey: "video1" | "video2") => {
    this.setState({
      confirmDelete: true,
      videoToDelete: videoKey,
    });
  };

  handleDeleteConfirm = () => {
    const { videoToDelete } = this.state;
    if (videoToDelete) {
      this.props.handleDelete(videoToDelete);
    }
    this.setState({
      confirmDelete: false,
      videoToDelete: null,
    });
  };

  handleDeleteCancel = () => {
    this.setState({
      confirmDelete: false,
      videoToDelete: null,
    });
  };

  render() {
    const { show, t, handleClose, video, handleUploadFile, handleSave } =
      this.props;
    const {
      confirmDelete,
      videoToDelete,
      showNotification,
      message,
      notificationStatus,
    } = this.state;
    const {
      video1,
      video1comment,
      encodedVideoName1,
      video1aws,
      video2,
      video2comment,
      encodedVideoName2,
      video2aws,
      transactionId,
      videoID,
    } = video;

    const handleCloseAndSave = () => {
      handleClose();
      handleSave();
    };

    return (
      <Container id="ModalContainer" show={show}>
        <ModalContent id="ModalContent">
          <Notification
            show={showNotification}
            message={message}
            status={notificationStatus}
          />
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
          >
            <BackButton onClick={handleCloseAndSave}>
              <ChevronLeft />
            </BackButton>
            <div
              style={{
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
                flex: 1,
                marginLeft: "-40px",
              }}
            >
              Review Video
            </div>
          </div>
          <Content>
            {video1aws ? (
              <VideoContainer>
                <video
                  src={getSignedUrl(transactionId, encodedVideoName1)}
                  width="100%"
                  height="100%"
                  controls
                />
                {video1comment && (
                  <Comment>{`Comments: ${video1comment}`}</Comment>
                )}
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                      window.open(
                        getSignedUrl(transactionId, encodedVideoName1)
                      )
                    }
                    style={{
                      backgroundColor: "#6898E3",
                      border: "none",
                      padding: "8px 12px",
                      marginTop: "10px",
                      cursor: "pointer",
                      fontSize: "16px",
                      borderRadius: "4px",
                    }}
                  >
                    Download
                  </Button>
                  {videoID !== 1 && (
                    <DeleteButton
                      onClick={() => this.showDeleteConfirm("video1")}
                    >
                      <DeleteOutlined /> {t("admin.result.delete")}
                    </DeleteButton>
                  )}
                </div>
              </VideoContainer>
            ) : (
              <VideoContainer>
                {video1 ? (
                  <>
                    <Video onClick={() => window.open(video1, "_blank")}>
                      {video1}
                    </Video>
                    {video1comment && (
                      <Comment>{`Comments: ${video1comment}`}</Comment>
                    )}
                    {videoID !== 1 && (
                      <DeleteButton
                        onClick={() => this.showDeleteConfirm("video1")}
                      >
                        <DeleteOutlined />
                        {t("admin.result.delete")}
                      </DeleteButton>
                    )}
                  </>
                ) : (
                  <Dragger
                    beforeUpload={() => false}
                    onChange={handleUploadFile}
                    accept="audio/x-m4a,audio/*,video/*"
                    style={{
                      padding: "20px",
                      borderRadius: "4px",
                      backgroundColor: "#f5f5f5",
                      border: "1px dashed #d9d9d9",
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined
                        style={{ fontSize: "32px", color: "#1890ff" }}
                      />
                    </p>
                    <p className="ant-upload-text">
                      Drag & drop or click to upload video
                    </p>
                    <p className="ant-upload-hint">
                      Supports audio and video formats.
                    </p>
                  </Dragger>
                )}
              </VideoContainer>
            )}

            {video2aws ? (
              <VideoContainer>
                <video
                  src={getSignedUrl(transactionId, encodedVideoName2)}
                  width="100%"
                  height="100%"
                  controls
                />
                {video2comment && (
                  <Comment>{`Comments: ${video2comment}`}</Comment>
                )}
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                      window.open(
                        getSignedUrl(transactionId, encodedVideoName2)
                      )
                    }
                    style={{
                      backgroundColor: "#6898E3",
                      border: "none",
                      padding: "8px 12px",
                      marginTop: "10px",
                      cursor: "pointer",
                      fontSize: "16px",
                      borderRadius: "4px",
                    }}
                  >
                    Download
                  </Button>
                  {videoID !== 1 && (
                    <DeleteButton
                      onClick={() => this.showDeleteConfirm("video2")}
                    >
                      <DeleteOutlined /> {t("admin.result.delete")}
                    </DeleteButton>
                  )}
                </div>
              </VideoContainer>
            ) : (
              <VideoContainer>
                {video2 ? (
                  <>
                    <Video onClick={() => window.open(video2, "_blank")}>
                      {video2}
                    </Video>
                    {video2comment && (
                      <Comment>{`Comments: ${video2comment}`}</Comment>
                    )}
                    {videoID !== 1 && (
                      <DeleteButton
                        onClick={() => this.showDeleteConfirm("video2")}
                      >
                        <DeleteOutlined /> {t("admin.result.delete")}
                      </DeleteButton>
                    )}
                  </>
                ) : (
                  <Dragger
                    beforeUpload={() => false}
                    onChange={handleUploadFile}
                    accept="audio/x-m4a,audio/*,video/*"
                    style={{
                      padding: "20px",
                      borderRadius: "4px",
                      backgroundColor: "#f5f5f5",
                      border: "1px dashed #d9d9d9",
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined
                        style={{ fontSize: "32px", color: "#1890ff" }}
                      />
                    </p>
                    <p className="ant-upload-text">
                      Drag & drop or click to upload video
                    </p>
                    <p className="ant-upload-hint">
                      Supports audio and video formats.
                    </p>
                  </Dragger>
                )}
              </VideoContainer>
            )}
          </Content>
        </ModalContent>

        {/* Delete Confirmation Modal */}
        <Modal
          title="Delete Video"
          visible={confirmDelete}
          onOk={this.handleDeleteConfirm}
          onCancel={this.handleDeleteCancel}
          okText="Delete"
          cancelText="Cancel"
        >
          <p>
            Are you sure you want to delete{" "}
            {videoToDelete === "video1" ? "Video 1" : "Video 2"}?
          </p>
        </Modal>
      </Container>
    );
  }
}

export default withTranslation()(VideoModal);
