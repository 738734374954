import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, Tooltip, ConfigProvider } from "antd";
import api_address from "../../../constants/config";
import { ADMIN_COOKIE_NAME, LIMIT } from "../../../constants/admin";
import { GET_DATE_TIME_STRING_FROM_TIMESTAMP } from "../../../util/common";
import { JS_COOKIE } from "util/auth";
import { Container, AdminTableContainer } from "../styles";
import { LinkOutlined, FileTextOutlined } from "@ant-design/icons";
import enUS from "antd/es/locale/en_US";
import { PRODUCTS } from "../../../constants/common";
import {
  EVAL_STEPS,
  VIDEO_STATUS,
  QUESTIONNAIRE_STEPS,
} from "../../../constants/common";

interface UserType {
  id: string;
  UUID: string;
  firstName: string;
  lastName: string;
  currentstep: string;
  productName: string;
  createdat: string;
  lastloginat: string;
}

interface SorterState {
  field: string;
  order: "ascend" | "descend" | undefined;
}

const User: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [userData, setUserData] = useState<UserType[]>([]);
  const [filteredData, setFilteredData] = useState<UserType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<SorterState | null>(null);
  const [filters, setFilters] = useState<{
    currentstep?: string[];
    productName?: string[];
  }>({});
  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(25);

  const getUserData = async () => {
    setLoading(true);
    try {
      const token = JS_COOKIE.get(ADMIN_COOKIE_NAME);
      const { data } = await axios.get(api_address + "api/admin/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedData = sortData(data.user, sorter);
      setUserData(sortedData);
      setFilteredData(sortedData);
      setTotal(data.rowCount);
      setLoading(false);
      return {
        data: sortedData,
        success: true,
        total: data.rowCount,
      };
    } catch (err) {
      console.error(err);
      setLoading(false);
      return {
        data: [],
        success: false,
        total: 0,
      };
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    applyFiltersAndSort();
  }, [filters, sorter, userData, searchText]);

  const applyFiltersAndSort = () => {
    let result = [...userData];

    if (searchText) {
      result = result.filter((user) =>
        ["email", "firstName", "lastName"].some((key) =>
          user[key]?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }

    if (filters.currentstep && filters.currentstep.length > 0) {
      result = result.filter((user) =>
        filters.currentstep!.includes(user.currentstep)
      );
    }

    if (filters.productName && filters.productName.length > 0) {
      result = result.filter((payment) =>
        filters.productName!.includes(payment.productName)
      );
    }

    result = sortData(result, sorter);

    setFilteredData(result);
    setTotal(result.length);
  };

  const productFilters = Object.keys(PRODUCTS).map((key) => ({
    text: PRODUCTS[key].replace(/([A-Z])/g, " $1").trim(),
    value: PRODUCTS[key],
  }));

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return <Tooltip title={text}>{text.slice(0, maxLength)}...</Tooltip>;
  };

  const sortData = (data: UserType[], currentSorter: SorterState | null) => {
    if (!currentSorter) return data;

    return [...data].sort((a, b) => {
      if (currentSorter.order === "ascend") {
        return a[currentSorter.field] > b[currentSorter.field] ? 1 : -1;
      } else if (currentSorter.order === "descend") {
        return a[currentSorter.field] < b[currentSorter.field] ? 1 : -1;
      }
      return 0;
    });
  };

  const renderDate = (text: string) => {
    if (!text || text === "-" || text.includes("NaN")) {
      return "-";
    }
    return GET_DATE_TIME_STRING_FROM_TIMESTAMP(text);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const newSorter: SorterState = {
      field: sorter.field,
      order: sorter.order,
    };
    setSorter(newSorter);
    setFilters(filters);
    setPageSize(pagination.pageSize);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: t("admin.user.id"),
      dataIndex: "id",
      sorter: true,
      hideInSearch: false,
    },
    {
      title: t("admin.user.email"),
      dataIndex: "email",
      sorter: true,
      hideInSearch: false,
      render: (text: string) => truncateText(text, 15),
    },
    {
      title: t("admin.user.lastName"),
      dataIndex: "lastName",
      sorter: true,
      hideInSearch: false,
      render: (text: string) => truncateText(text, 15),
    },
    {
      title: t("admin.user.firstName"),
      dataIndex: "firstName",
      sorter: true,
      hideInSearch: false,
      render: (text: string) => truncateText(text, 15),
    },
    {
      title: t("admin.user.currentStep"),
      dataIndex: "currentstep",
      hideInSearch: true,
      render: (text: string) => {
        if (!text || text === "-") {
          return "-";
        }
        return t(`admin.user.${text}`);
      },
      filters: [
        {
          text: "Instructions",
          value: [EVAL_STEPS.basicIntro, EVAL_STEPS.kksIntro],
        },
        {
          text: "Evaluation",
          value: EVAL_STEPS.questionnaire,
        },
        {
          text: "Child information",
          value: EVAL_STEPS.childInfo,
        },
        {
          text: "Sound",
          value: EVAL_STEPS.soundCollection,
        },
        {
          text: "Upload video",
          value: EVAL_STEPS.video,
        },
        {
          text: "Waiting for assign",
          value: VIDEO_STATUS.waiting,
        },
        {
          text: "Therapist reviewing",
          value: VIDEO_STATUS.reviewing,
        },
        {
          text: "Report ready for review",
          value: EVAL_STEPS.report,
        },
      ],
    },
    {
      title: t("admin.user.product"),
      dataIndex: "productName",
      filters: productFilters,
      sorter: false,
      filterMultiple: true,
    },
    {
      title: t("admin.user.lastLoginAt"),
      dataIndex: "lastloginat",
      render: renderDate,
      sorter: true,
      hideInSearch: true,
    },
    {
      title: t("admin.user.createdAt"),
      dataIndex: "createdat",
      render: renderDate,
      sorter: true,
      hideInSearch: true,
    },
    {
      dataIndex: "hasAssessment",
      hideInSearch: true,
      render: (hasAssessment) => {
        if (!hasAssessment) {
          return "-";
        } else {
          return <FileTextOutlined style={{ fontSize: 25 }} />;
        }
      },
    },
  ];
  const hideCreateButton = true;

  return (
    <ConfigProvider locale={enUS}>
      <AdminTableContainer>
        <Input.Search
          placeholder={"name, user ID, email"}
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
        <ProTable<UserType>
          columns={columns}
          dataSource={filteredData}
          loading={loading}
          rowKey="id"
          pagination={{
            showQuickJumper: true,
            total: total,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
          search={false}
          dateFormatter="string"
          headerTitle={t("admin.user.title")}
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          toolBarRender={() => [
            <Button
              key="create"
              onClick={() => history.push("/admin/user/create")}
              style={{ display: hideCreateButton ? "none" : "inline-block" }}
            >
              {t("admin.user.create")}
            </Button>,
            selectedRowKeys.length === 1 && (
              <Button
                key="edit"
                onClick={() =>
                  history.push(`/admin/user/edit/${selectedRowKeys[0]}`)
                }
              >
                {t("admin.user.edit")}
              </Button>
            ),
          ]}
          onChange={handleTableChange}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                history.push(`/admin/user/edit/${record.id}`);
                window.scrollTo(0, 0);
              },
            };
          }}
        />
      </AdminTableContainer>
    </ConfigProvider>
  );
};

export default User;
