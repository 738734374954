import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  top: 0%;
  left:0%;
  width 100%;
  height: 100%;
  display: ${(props: { show: boolean }) => (props.show ? "block" : "none")};
  background: rgba(0, 0, 0, 0.95);
  overscroll-behavior: contain;
  overflow: auto;
  padding-top: 100px;
`;

export const ModalContent = styled.div`
  border-radius: 15px;
  background-color: white;
  padding: 0 30px 35px 30px;
  margin: auto;
  width: 40%;
  overflow: auto;
  height: 280px;
  text-align: -webkit-center;
`;

export const Header = styled.div`
  font-size: 25px;
  padding-top: 40px;
  height: 60px;
`;

export const CloseButton = styled.div`
  color: black;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  border: black solid 1px;
  width: 180px;
  border-radius: 4px;
  padding: 10px;
`;

export const Content = styled.div`
  // padding-top: 60px;
  // color: #FFFFFF;
  margin-top: 20px;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  min-height: 50px;
`;
