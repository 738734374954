import React from "react";
import i18n from "i18n";
import ReactDOM from "react-dom";
import { RouteComponentProps, withRouter, match } from "react-router-dom";
import {
  LOGIN_VALIDATION,
  DEVELOPER_LOGIN_SUBMIT,
  LOGIN_SUBMIT,
  REGISTER_VALIDATION,
  REGISTER_SUBMIT,
} from "../../../../util/auth";
import { withTranslation, WithTranslation } from "react-i18next";
import { History, LocationState } from "history";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./Stripe/StripeCheckoutForm";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { bindActionCreators } from "redux";
import * as qs from "query-string";
import mixpanel from "mixpanel-browser";
import api_address from "../../../../constants/config";
import { startLoginSuccess, startUpdateUserToken } from "../../../auth/actions";
import { AppActions } from "types/actions";
import WeChatPayForm from "./WeChatPayFrom";
import AliPayForm from "./AliPayForm";
import { GET_TITLE_BAR } from "util/common";
import { ROLE, CURRENCY, PRODUCTS } from "../../../../constants/common";
import GoToSurveyModal from "../../../common/GoToSurveyModal";
import NotificationModal from "../../../common/NotificationModal";
import LoginModal from "../../../auth/UserAuthModal/LoginModal";
import RegisterModal from "../../../auth/UserAuthModal/RegisterModal";
import {
  Container,
  PaymentContainer,
  SummaryContainer,
  CheckoutContainer,
  PaymentMethodContainer,
  CreditCardContainer,
  // WechatPayContainer,
  AliPayContainer,
  SectionTitle,
  Title,
  InputArea,
  ApplyButtonContainer,
  ApplyCouponContainer,
  StripePaymentContainer,
  ExtraInfoContainer,
  TableRow,
  TableData,
  TablePriceData,
  Table,
  ProductData,
  ProductRow,
  TotalPrice,
  TotalPriceRow,
  TermsLink,
  MobileSummaryContainer,
} from "./styles";
import { ApplyCouponButton, PayButton } from "styles";
import { getCountryInitial } from "constants/countryCode";
import countryList from "constants/countryList";
import { getUserBrowserTz } from "constants/timeZone";
import RedirectToNanshanLandingPagePopUp from "components/User/ProfileRoute/RedirectToNanshanLandingPagePopUp";
import NoCoreProModal from "../../../common/NoCoreProModal";
import { OAUTH_METHOD, OAUTH_ERROR_MESSAGE } from "../../../auth/constants";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);
interface CheckoutPaymentProps {
  match?: match<Match>;
  history: History<LocationState>;
  stripe: any;
  elements: any;
}

interface Match {
  carriedCoupon: string;
}

interface CheckoutPaymentState {
  [key: string]: boolean | string;
  isBuyUp: boolean;
  productId: string;
  paymentMethod: string;
  price: string;
  discount: string;
  discountedPrice: string;
  currency: string;
  product: string;
  coupon: string;
  hasUnfinishedSurvey: boolean;
  couponDisable: boolean;
  login: boolean;
  register: boolean;
  selectCard: boolean;
  selectWechat: boolean;
  selectAli: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  countryCode: string;
  country: string;
  isPhone: boolean;
  isAgreed: boolean;
  requireOrganizationCode: boolean;
  organizationCode: string;
  notificationStatus: boolean;
  message: string;
  showNotification: boolean;
  timeZone: string;
  qsResult: any;
  isEligibleToPurchaseCPCBuyUp: boolean;
  errors: string;
  showModal: boolean;
}

type Props = CheckoutPaymentProps &
  WithTranslation &
  LinkDispatchProps &
  RouteComponentProps;

class CheckoutPayment extends React.Component<Props, CheckoutPaymentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBuyUp: false,
      productId: "",
      product: "",
      currency: "",
      discount: "0",
      price: "0",
      discountedPrice: "",
      coupon: "",
      couponDisable: false,
      hasUnfinishedSurvey: false,
      paymentMethod: "stripe",
      login: false,
      register: false,
      selectCard: false,
      selectWechat: false,
      selectAli: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      countryCode: "",
      country: "",
      password: "",
      requireOrganizationCode: false,
      organizationCode: "",
      isPhone: false,
      isAgreed: false,
      showNotification: false,
      message: "",
      notificationStatus: false,
      errors: "",
      showModal: false,
      timeZone: getUserBrowserTz(),
      isEligibleToPurchaseCPCTrial: false,
      qsResult: {},
      isEligibleToPurchaseCPCBuyUp: false,
    };
  }

  private previousToken: string | undefined = null;

  componentDidMount = async () => {
    try {
      const { t } = this.props;
      document.title = GET_TITLE_BAR(t, "checkout", true);
      const token = Cookies.get("token");
      this.previousToken = token;

      const {
        coupon,
        product,
        currency,
        isBuyUp,
        org: organizationCode,
      } = qs.parse(window.location.search);

      const isLoggedIn = token != null;
      if (isLoggedIn) {
        const hasUnfinishedSurvey = await this.checkIfUserHasUnfinishedSurvey();
        if (hasUnfinishedSurvey) {
          this.setState({
            login: false,
            hasUnfinishedSurvey: true,
          });
          return;
        }
      }
      if (organizationCode) {
        this.setState({
          organizationCode: organizationCode,
          requireOrganizationCode: true,
        });
      }

      if (product == null || currency == null) {
        this.props.history.push("/login");
      }

      if (product == PRODUCTS.CPCTrial && isLoggedIn) {
        const hasAlreadyDoneQuickScreener =
          await this.checkIfUserHasAlreadyDoneQuickScreener();

        if (hasAlreadyDoneQuickScreener) {
          this.setState({ isEligibleToPurchaseCPCTrial: true });
        }
      }

      if (product == PRODUCTS.CPCBuyUp && isLoggedIn) {
        const hasAlreadyDoneCorePro =
          await this.checkIfUserHasAlreadyDoneCorePro();
        this.setState({ isEligibleToPurchaseCPCBuyUp: hasAlreadyDoneCorePro });
      }

      const hasCarriedCoupon = coupon != null;
      let productInfo;
      if (!hasCarriedCoupon) {
        productInfo = await this.getProductInfoByNameAndCurrency(
          product,
          currency
        );
      } else {
        productInfo = await this.getProductByCarryingCoupon(
          coupon,
          product,
          currency
        );
      }

      const { price, productId, discount, discountedPrice } = productInfo;
      this.setState({
        isBuyUp: isBuyUp === "true",
        productId,
        discount,
        discountedPrice,
        product,
        price,
        currency,
        coupon,
        couponDisable: hasCarriedCoupon,
        register: !isLoggedIn,
      });
      window.scrollTo(0, 0);
    } catch (err) {
      console.error("debug err purchase", err);
      this.props.history.push("/login");
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const currentToken = Cookies.get("token");

    // Check if the token has changed
    if (currentToken !== this.previousToken) {
      // Update the previousToken for the next comparison
      this.previousToken = currentToken;

      const isLoggedIn = currentToken != null;
      if (isLoggedIn) {
        this.checkIfUserHasUnfinishedSurvey()
          .then((hasUnfinishedSurvey) => {
            if (hasUnfinishedSurvey) {
              this.setState({
                hasUnfinishedSurvey: hasUnfinishedSurvey,
              });
            }
          })
          .catch((err) => {
            console.error("Error checking unfinished survey status", err);
          });

        this.checkIfUserHasAlreadyDoneQuickScreener()
          .then((hasDoneQuickScreener) => {
            this.setState({
              isEligibleToPurchaseCPCTrial: hasDoneQuickScreener,
            });
          })
          .catch((err) => {
            console.error("Error checking quick screener status", err);
          });

        this.checkIfUserHasAlreadyDoneCorePro()
          .then((hasAlreadyDoneCorePro) => {
            this.setState({
              isEligibleToPurchaseCPCBuyUp: hasAlreadyDoneCorePro,
            });
          })
          .catch((err) => {
            console.error("Error checking CorePro status", err);
          });
      } else {
        // Handle case where user is not logged in or token is null
        this.setState({
          login: true,
          hasUnfinishedSurvey: false,
          isEligibleToPurchaseCPCTrial: false,
          isEligibleToPurchaseCPCBuyUp: false,
        });
      }
    }
  }

  checkIfUserHasAlreadyDoneQuickScreener = async () => {
    const token = Cookies.get("token");
    if (!token) {
      return false;
    }
    const qsRes = await axios.get(
      api_address + "api/quickScreenerResult/getQSResultByUserId",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { qsResult } = qsRes.data ?? {};
    this.setState({ qsResult: qsResult });
    return qsResult.length > 0;
  };

  checkIfUserHasAlreadyDoneCorePro = async () => {
    const token = Cookies.get("token");
    if (!token) {
      return false;
    }
    const res = await axios.get(api_address + "api/results/getResultByUserId", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { result } = res.data ?? {};
    if (result.length > 0) {
      const hasPreviousCompletedCoreProResult = result.some(
        (item) => item.product === PRODUCTS.CorePro && item.completed === true
      );
      this.setState({
        isEligibleToPurchaseCPCBuyUp: hasPreviousCompletedCoreProResult,
      });
      return hasPreviousCompletedCoreProResult;
    }
    return false;
  };

  checkIfUserHasUnfinishedSurvey = async () => {
    const token = Cookies.get("token");
    const res = await axios.get(api_address + "api/user/surveys", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { transactions } = res.data;
    const hasUnfinishedSurvey = transactions && transactions.length > 0;
    return hasUnfinishedSurvey;
  };

  getProductInfoByNameAndCurrency = async (
    product: string,
    currency: string
  ) => {
    const res = await axios.get(api_address + "api/payment/checkout", {
      params: {
        productName: product,
        currency,
      },
    });
    const { status, message } = res.data;
    if (!status) throw new Error(message);
    return {
      price: res.data.price,
      productId: res.data.productId,
      discount: "0",
      discountedPrice: res.data.price,
    };
  };

  getProductByCarryingCoupon = async (
    coupon: string,
    product: string,
    currency: string
  ) => {
    const res = await axios.get(api_address + "api/coupon/couponCheckout", {
      params: {
        couponCode: coupon.trim(),
        productName: product,
        currency,
      },
    });
    const { status, message } = res.data;
    if (!status) throw new Error(message);
    return {
      price: res.data.price,
      productId: res.data.productId,
      discount: res.data.discount,
      discountedPrice: res.data.discountedPrice,
    };
  };

  displayErrorMessage = (message: string) => {
    this.setState({
      errors: message,
      showModal: true,
    });
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 3000);
  };

  handleGoogleAuth = async (oAuthResponse: any) => {
    if (oAuthResponse != null) {
      const newUser = {
        firstName: oAuthResponse.given_name ?? "",
        lastName: oAuthResponse.family_name ?? "",
        email: oAuthResponse.email,
        phone: "1234567890",
        countryCode: "+1",
        requireOrganizationCode: false,
        organizationCode: "",
        password: oAuthResponse.sub,
        isAgreed: true,
        isOauth: true,
      };
      let user: { token: string; role: string };
      try {
        user = await LOGIN_SUBMIT(
          {
            email: oAuthResponse.email,
            password: oAuthResponse.sub,
          },
          OAUTH_METHOD.GOOGLE
        );
        const { token, role } = user;
        this.props.onUpdateUserToken(token);
        if (role === ROLE.user) {
          this.props.onLoginSuccess(ROLE.user);
          this.setState({ register: false });
          this.props.history.push(
            this.props.location.pathname + this.props.location.search
          );
        } else if (role === ROLE.therapist) {
          this.props.onLoginSuccess(ROLE.therapist);
          this.setState({ register: false });
          this.props.history.push(
            this.props.location.pathname + this.props.location.search
          );
        }
      } catch (error: any) {
        if (error.message === OAUTH_ERROR_MESSAGE) {
          this.displayErrorMessage(i18n.t("message.account.methodError"));
        } else {
          this.props.history.push({
            pathname: "/oauth-register",
            state: {
              newUser: newUser,
              redirectLink:
                this.props.location.pathname + this.props.location.search,
            },
          });
        }
      }
    }
  };
  handleOpen = () => this.setState({ login: true });
  handleClose = () => this.setState({ login: false });
  handleRegister = () => this.setState({ login: false, register: true });
  handleLogin = () => this.setState({ login: true, register: false });
  handleGoToSurvey = () => this.props.history.push("/evaluation");
  handleGoBack = () => this.props.history.push("/");

  handleApplyCoupon = async () => {
    try {
      mixpanel.track("Payment", {
        action: "click apply coupon",
      });
      const token = Cookies.get("token");
      const { coupon, currency } = this.state;
      if (coupon === "") return;
      const res = await axios.post(
        api_address + "api/payment/applyCoupon",
        {
          code: coupon.trim(),
          currency,
          productID: this.state.productId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { discount, discountedPrice, status, message } = res.data;
      if (!status) {
        throw new Error(message);
      }
      this.setState({
        discountedPrice,
        discount,
        couponDisable: true,
      });
    } catch (err: any) {
      this.showNotification(err.message, false);
      mixpanel.track("Payment", {
        action: "apply coupon failed",
        errorMessage: err.message,
      });
    }
  };

  handleCouponChange = async () => {
    try {
      mixpanel.track("Payment", {
        action: "click change coupon",
      });
      const { price } = this.state;
      this.setState({
        discount: "0",
        discountedPrice: price,
        coupon: "",
        couponDisable: false,
      });
    } catch (err: any) {
      console.error(err.message);
    }
  };

  handlePay = () => {
    const { product, productId, paymentMethod, isEligibleToPurchaseCPCTrial } =
      this.state;
    if (product == PRODUCTS.CPCTrial && !isEligibleToPurchaseCPCTrial) return;
    switch (paymentMethod) {
      case "stripe":
        return;
      case "alipay":
        this.handleAlipay();
        return;
      case "wechatpay":
        this.handleWeChatPay();
        return;
      default:
        return "stripe";
    }
  };

  handleAlipay = async () => {
    try {
      const token = Cookies.get("token");
      const { productId, currency, coupon, discountedPrice, isBuyUp } =
        this.state;
      const res = await axios.post(
        api_address + "api/payment/alipayCheckout",
        {
          productID: productId,
          currency,
          couponCode: coupon.trim(),
          price: discountedPrice,
          isBuyUp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { status, message } = res.data;
      if (!status) throw new Error(message);
      mixpanel.track("Payment", {
        action: "AliPay checkout",
      });
      ReactDOM.render(
        <AliPayForm params={res.data.params} />,
        document.getElementById("root")
      );
    } catch (err: any) {
      this.showNotification(err.message, false);
    }
  };

  handleWeChatPay = async () => {
    try {
      const productId = this.state;
      const url = process.env.REACT_APP_NIHAOPAY_URL || "";
      const header = {
        Authorization: `Bearer ${process.env.REACT_APP_NIHAOPAY_TOKEN}`,
      };
      const { discountedPrice, currency } = this.state;
      const token = Cookies.get("token");
      const res = await axios.post(
        api_address + "api/payment/weChatPayCheckout",
        {
          price: discountedPrice,
          productID: productId,
          currency,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //TODO change the price after production
      const { transactionId } = res.data;
      const data = {
        // amount: "100",
        rmb_amount: "100",
        currency: "USD",
        vendor: "wechatpay",
        reference: transactionId,
        ipn_url: process.env.REACT_APP_NIHAOPAY_IPN_URL,
        callback_url: process.env.REACT_APP_NIHAOPAY_CALLBACK_URL,
      };
      const wechatpayRes = await axios.post(url, data, {
        headers: header,
      });
      const { status, message } = wechatpayRes.data;
      if (!status) throw new Error(message);
      mixpanel.track("Payment", {
        action: "WeChatPay checkout",
      });
      ReactDOM.render(
        <WeChatPayForm data={res.data} />,
        document.getElementById("root")
      );
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  handleCouponCheckout = async () => {
    const {
      product,
      productId,
      currency,
      discountedPrice,
      coupon,
      isBuyUp,
      isEligibleToPurchaseCPCTrial,
    } = this.state;
    if (product == PRODUCTS.CPCTrial && !isEligibleToPurchaseCPCTrial) return;
    const data = {
      productID: productId,
      currency,
      price: discountedPrice,
      couponCode: coupon.trim(),
      isBuyUp,
      paymentMethod: "coupon",
    };
    const token = Cookies.get("token");
    const res = await axios.post(
      api_address + "api/payment/couponCheckout",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { status, message } = res.data;
    if (!status) throw new Error(message);
    mixpanel.track("Payment", {
      action: "coupon checkout",
    });
    this.props.history.push("/evaluation");
  };

  onMethodSelect = (method: string) => {
    mixpanel.track("Payment", {
      action: "select payment method",
      from: this.state.paymentMethod,
      to: method,
    });
    this.setState({
      paymentMethod: method,
    });
  };

  onTermsClick = () => {
    mixpanel.track("Payment", {
      action: "terms clicked",
    });
    window.open("/terms-conditions");
  };

  handlePaymentSelected = () => {
    const { t } = this.props;
    const { paymentMethod } = this.state;
    switch (paymentMethod) {
      case "stripe":
        return (
          <StripePaymentContainer>
            <Elements stripe={stripePromise}>
              <CheckoutForm {...this.state} />
            </Elements>
            <TermsLink onClick={this.onTermsClick}>
              {t("user.purchase.payments.checkoutPayment.terms")}
            </TermsLink>
          </StripePaymentContainer>
        );
      case "alipay":
        return (
          <ExtraInfoContainer>
            <div style={{ fontWeight: "bold" }}>
              {t("user.purchase.payments.checkoutPayment.alipayNotification")}
            </div>
            <div>{t("user.purchase.payments.checkoutPayment.alipayDesc")}</div>
            <TermsLink onClick={this.onTermsClick}>
              {t("user.purchase.payments.checkoutPayment.terms")}
            </TermsLink>
          </ExtraInfoContainer>
        );
      case "wechatpay":
        return (
          <ExtraInfoContainer>
            <div>
              {t("user.purchase.payments.checkoutPayment.wechatpayDesc")}
            </div>
            <TermsLink onClick={this.onTermsClick}>
              {t("user.purchase.payments.checkoutPayment.terms")}
            </TermsLink>
          </ExtraInfoContainer>
        );
      default:
        return <div />;
    }
  };

  showNotification = (message: string, status: boolean) => {
    this.setState({
      message: message,
      notificationStatus: status,
      showNotification: true,
    });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 3000);
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const isEmail = id === "email";
    const isCoupon = id === "coupon";
    this.setState({
      [id]: isEmail || isCoupon ? value.replace(/\s+/g, "") : value,
    });
  };

  handleCountryCodeChange = (value: string) => {
    if (value != null)
      this.setState({
        countryCode: value,
      });
    const countryCode = getCountryInitial(value);
    for (let i = 0; i < countryList.length; i++) {
      if (countryList[i].key === countryCode?.toLowerCase()) {
        this.setState({
          country: countryList[i].key,
        });
      }
    }
  };

  handleCountryChange = (event, { value }) => {
    if (value != null)
      this.setState({
        country: value,
      });
  };

  handleTimeZoneChange = (event, { value }) => {
    if (value != null)
      this.setState({
        timeZone: value,
      });
  };

  handleTimeZoneClick = (event, { value }) => {
    this.setState({
      timeZone: getUserBrowserTz(),
    });
  };

  setIsPhone = () => {
    this.setState((states) => ({
      isPhone: !states.isPhone,
    }));
  };

  setIsAgreed = () => {
    this.setState((states) => ({
      isAgreed: !states.isAgreed,
    }));
  };

  onLoginSubmit = async () => {
    try {
      const { phone, email, countryCode, password, isPhone } = this.state;
      let user: { token: string; role: string };
      if (
        email === "developer@ps.com" &&
        process.env.REACT_APP_ENVIRONMENT === "development"
      ) {
        user = await DEVELOPER_LOGIN_SUBMIT({ email });
      } else {
        const userData = {
          countryCode,
          phone,
          email,
          password,
          isPhone,
        };
        LOGIN_VALIDATION(userData);
        user = await LOGIN_SUBMIT(userData);
      }
      const { token, role } = user;
      this.props.onUpdateUserToken(token);
      this.props.onLoginSuccess(ROLE.user);
      this.setState({ login: false });
      if (role === ROLE.therapist) {
        this.props.history.push("/therapist");
      }
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  onRegisterSubmit = async () => {
    try {
      const {
        firstName,
        lastName,
        countryCode,
        country,
        email,
        phone,
        password,
        isAgreed,
        requireOrganizationCode,
        organizationCode,
        timeZone,
      } = this.state;
      const newUser = {
        firstName,
        lastName,
        email,
        phone,
        countryCode,
        country,
        password,
        requireOrganizationCode,
        organizationCode,
        isAgreed,
        timeZone,
      };
      REGISTER_VALIDATION(newUser);
      const token = await REGISTER_SUBMIT(newUser);
      this.setState({ register: false });
      this.props.onUpdateUserToken(token);
      this.props.onLoginSuccess(ROLE.user);
    } catch (error: any) {
      this.showNotification(error.message, false);
    }
  };

  onFocus = () => {
    mixpanel.track("Payment", {
      action: "focus on coupon",
    });
  };

  render() {
    const { t } = this.props;
    const {
      register,
      couponDisable,
      coupon,
      login,
      showNotification,
      message,
      notificationStatus,
      product,
      price,
      discount,
      discountedPrice,
      currency,
      paymentMethod,
      hasUnfinishedSurvey,
      showModal,
      errors,
    } = this.state;
    const currencyPresent = currency === CURRENCY.cny ? "¥" : "$";
    const summaryContent = (
      <>
        <SectionTitle>
          {t("user.purchase.payments.checkoutPayment.orderSummary")}
        </SectionTitle>
        {product && (
          <Table>
            <ProductRow>
              <ProductData>
                {t("user.purchase.payments.checkoutPayment." + product)} × 1
              </ProductData>
              <TablePriceData>{price}</TablePriceData>
            </ProductRow>

            {product == PRODUCTS.CPCBH ? (
              <ProductData>
                <ul>
                  <li>
                    Communication Development Evaluation - observational
                    evaluation, highlighting 9 key communication areas, as well
                    as your child's communication status and environment.
                  </li>
                  <li>
                    Customized Private Coaching (CPC) - comprehensive, tailored,
                    family coaching program guided by a dedicated expert team of
                    SLPs and coaches.
                  </li>
                  <li>
                    Post-CPC Evaluation - Additional evaluation to measure
                    progress over the CPC program
                  </li>
                </ul>
              </ProductData>
            ) : null}
            {discount !== "0" && (
              <TableRow>
                <TableData>
                  {t("user.purchase.payments.checkoutPayment.discount")}
                </TableData>
                <TablePriceData>{"- " + discount}</TablePriceData>
              </TableRow>
            )}

            <TotalPriceRow>
              <TableData>
                {t("user.purchase.payments.checkoutPayment.total")}
              </TableData>
              <TotalPrice>
                {currency?.toUpperCase() +
                  " " +
                  parseFloat(discountedPrice).toFixed(2)}
              </TotalPrice>
            </TotalPriceRow>
          </Table>
        )}
      </>
    );

    return (
      <>
        <LoginModal
          {...this}
          {...this.state}
          isOpen={login}
          history={this.props.history}
        />
        <RegisterModal
          {...this.state}
          {...this}
          showOrganizationCodeInput={this.state.requireOrganizationCode}
          isOpen={register}
          history={this.props.history}
        />
        <RedirectToNanshanLandingPagePopUp
          history={this.props.history}
          display={
            !this.state.isEligibleToPurchaseCPCTrial &&
            product == PRODUCTS.CPCTrial &&
            this.previousToken !== undefined
          }
          qsResult={this.state.qsResult}
        />
        <GoToSurveyModal
          show={hasUnfinishedSurvey}
          handleClose={this.handleGoToSurvey}
        />
        <NoCoreProModal
          display={
            !this.state.isEligibleToPurchaseCPCBuyUp &&
            !(register || login) &&
            product == PRODUCTS.CPCBuyUp
          }
          title={t(
            "user.purchase.payments.checkoutPayment.noCoreProPreviouslyTitle"
          )}
          text={t(
            "user.purchase.payments.checkoutPayment.noCoreProProductPreviously"
          )}
          text2={t(
            "user.purchase.payments.checkoutPayment.noCoreProProductPreviously2"
          )}
          buttonText={t(
            "user.survey.corePro.waiting.ReportNotReadyModalButton1"
          )}
          onClick={this.handleGoBack}
        />
        <NotificationModal
          show={showNotification}
          message={message}
          status={notificationStatus}
        ></NotificationModal>
        <Container>
          <Title>{t("user.purchase.payments.checkoutPayment.title")}</Title>
          <PaymentContainer>
            <CheckoutContainer>
              <SectionTitle>
                {t("user.purchase.payments.checkoutPayment.applyCoupon")}
              </SectionTitle>
              <ApplyCouponContainer>
                <InputArea
                  onFocus={this.onFocus}
                  onChange={this.handleInputChange}
                  disabled={couponDisable}
                  id="coupon"
                  type="text"
                  value={coupon}
                />
                <ApplyButtonContainer>
                  {couponDisable ? (
                    <ApplyCouponButton onClick={this.handleCouponChange}>
                      {t("user.purchase.payments.checkoutPayment.change")}
                    </ApplyCouponButton>
                  ) : (
                    <ApplyCouponButton onClick={this.handleApplyCoupon}>
                      {t("user.purchase.payments.checkoutPayment.apply")}
                    </ApplyCouponButton>
                  )}
                </ApplyButtonContainer>
              </ApplyCouponContainer>
              <MobileSummaryContainer>{summaryContent}</MobileSummaryContainer>
              {parseInt(discountedPrice) > 0 ? (
                <>
                  <SectionTitle>
                    {t("user.purchase.payments.checkoutPayment.paymentMethod")}
                  </SectionTitle>
                  <PaymentMethodContainer>
                    <CreditCardContainer
                      onClick={() => this.onMethodSelect("stripe")}
                      status={paymentMethod}
                    />
                    {/* <WechatPayContainer
                  onClick={() => this.onMethodSelect("wechatpay")}
                  status={paymentMethod}
                ></WechatPayContainer> */}
                    {currency === CURRENCY.cny && (
                      <AliPayContainer
                        onClick={() => this.onMethodSelect("alipay")}
                        status={paymentMethod}
                      />
                    )}
                  </PaymentMethodContainer>
                  {this.handlePaymentSelected()}

                  {paymentMethod !== "stripe" && (
                    <PayButton
                      onClick={this.handlePay}
                      disabled={
                        !this.state.isEligibleToPurchaseCPCTrial &&
                        product == PRODUCTS.CPCTrial
                      }
                    >
                      {t("user.purchase.payments.checkoutPayment.pay")}
                      {/* {"   " + currencyPresent + discountedPrice} */}
                    </PayButton>
                  )}
                </>
              ) : (
                <>
                  <PayButton
                    onClick={this.handleCouponCheckout}
                    disabled={
                      !this.state.isEligibleToPurchaseCPCTrial &&
                      product == PRODUCTS.CPCTrial
                    }
                  >
                    {t("user.purchase.payments.checkoutPayment.pay")}
                    {/* {"   " + currencyPresent + discountedPrice} */}
                  </PayButton>
                  <TermsLink onClick={this.onTermsClick}>
                    {t("user.purchase.payments.checkoutPayment.terms")}
                  </TermsLink>
                </>
              )}
            </CheckoutContainer>
            <SummaryContainer>{summaryContent}</SummaryContainer>
          </PaymentContainer>
        </Container>
      </>
    );
  }
}

interface LinkDispatchProps {
  onLoginSuccess: (data: string) => void;
  onUpdateUserToken: (data: string) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>
): LinkDispatchProps => ({
  onLoginSuccess: bindActionCreators(startLoginSuccess, dispatch),
  onUpdateUserToken: bindActionCreators(startUpdateUserToken, dispatch),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(CheckoutPayment))
);
